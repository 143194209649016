import { ZA, GH, ZM, TZ, MW } from 'country-flag-icons/string/3x2';

export const regions = [
  { name: 'South Africa', code: 'ZA', flag: import.meta.dev ? ZA : null },
  { name: 'Ghana', code: 'GH', flag: import.meta.dev ? GH : null },
  { name: 'Zambia', code: 'ZM', flag: import.meta.dev ? ZM : null },
  { name: 'Tanzania', code: 'TZ', flag: import.meta.dev ? TZ : null },
  { name: 'Malawi', code: 'MW', flag: import.meta.dev ? MW : null },
];

export const brandIds = {
  JTZ: 'E430B4FB-55DC-42CC-AA65-1057CE49C7E6',
  JAO: 'FEE7672B-53BA-45B7-823F-1E61A9B9509B',
  JKE: 'B33B59F1-E3D4-449A-9696-2201A185A858',
  JET: '5A533B6D-80CC-42D0-802E-804E3662540A',
  JCM: 'F86AFCDE-0FCB-4197-9207-87EFD66409A1',
  JZM: 'D17024C3-F417-4D1C-B142-9E5F9E83B49B',
  JMZ: 'CFF8B795-9939-432F-A73D-B2393F8E658D',
  JZA: 'D76A0E62-B728-4A28-8134-C57A1A003199',
  JUG: '75EA7131-252E-44C3-B369-CC6DA7192FAE',
  JNG: 'DBAE2A59-1E73-4DA2-B169-CDA0239BCF78',
  JMW: 'CA968023-3E8E-43BC-B3AA-DB01C469A4C6',
  JGH: 'BE6CDE0B-73E4-448A-A706-F9C20CF3F669',
};

export const defaultLocales = {
  JTZ: 'en-US',
  JAO: 'en-US',
  JKE: 'sw-KE',
  JET: 'en-US',
  JCM: 'en-US',
  JZM: 'en-US',
  JMZ: 'pt-MZ',
  JZA: 'en-US',
  JUG: 'en-US',
  JNG: 'en-US',
  JMW: 'en-US',
  JGH: 'en-US',
};

export const subdomainLocales = {
  en: 'en-US',
  sw: 'sw-TZ',
  pt: 'pt-MZ',
};

export const brandDomainSuffix = {
  JTZ: 'co.tz',
  JAO: 'co.ag',
  JKE: 'co.ke',
  JET: 'co.et',
  JCM: 'cm',
  JZM: 'co.zm',
  JMZ: 'com.mz',
  JZA: 'co.za',
  JUG: 'ug',
  JNG: 'com.ng',
  JMW: 'mw',
  JGH: 'com.gh',
};

export const liveChatIds = {
  JTZ: '4e5e503d-2408-4516-ab2f-3e2528ff33c4',
  JMW: '113c467a-65ec-4e9d-b03b-9eb8a8eb3fc1',
  JZA: '0ac33e78-8b1b-4c3c-9209-cc5bbeedd930',
};

export const socialLinks = {
  JZA: {
    facebook: 'https://www.facebook.com/people/jackpotcitsa/61554440885293',
    instagram: 'https://www.instagram.com/jackpotcitysa',
    x: 'https://x.com/JackPotCitySA',
  },
  JTZ: {
    facebook: '',
    instagram: '',
    x: '',
  },
  JMW: {
    facebook: 'https://www.facebook.com/JackpotCityCasinoMalawi',
    instagram: 'https://www.instagram.com/jackpotcitycasinomw',
    x: 'https://x.com/JPCCasinoMW',
  },
};

export const captcha = {
  JZA: '6LfmZlwqAAAAADj8PrXTMfCnEuvlzFw_YbLjRePM',
  JMW: '6LdeSFwqAAAAAHqAGT1c0qJXO7e9HgUw7vw4pFRQ',
  JTZ: '6LfNH1wqAAAAAIgdhSVIboLhSPRr5onGjyclhUP_',
};

export const environmentVariables = {
  development: {
    kentico: 'https://uat.jackpotcity.co.za/api/kenticodv',
    authApi: 'https://dev-app.jpc.africa/auth',
    balance: 'https://dev-app.jpc.africa/balance',
    player: 'https://dev-app.jpc.africa/player',
    casinoBonus: 'https://dev-casinobonusing.jpc.africa',
    config: 'https://uat.jackpotcity.co.za/api/configdv',
    promotions: 'https://promoapi-dev.jpc.africa',
    environment: 'development',
    bankingEnvironment: 'dev.jackpotcity',
  },
  qa: {
    kentico: 'https://uat.jackpotcity.co.za/api/kenticostg',
    authApi: 'https://uat-app.jpc.africa/auth',
    balance: 'https://uat-app.jpc.africa/balance',
    player: 'https://uat-app.jpc.africa/player',
    casinoBonus: 'https://dev-casinobonusing.jpc.africa',
    config: 'https://uat.jackpotcity.co.za/api/configstg',
    promotions: 'https://promoapi-dev.jpc.africa',
    environment: 'staging',
    bankingEnvironment: 'qa.jackpotcity',
  },
  uat: {
    kentico: 'https://cms1.jpc.africa',
    authApi: 'https://app.jpc.africa/auth',
    balance: 'https://app.jpc.africa/balance',
    player: 'https://app.jpc.africa/player',
    casinoBonus: 'https://uat-casinobonusing.jpc.africa',
    config: 'https://uat.jackpotcity.co.za/api/configuat',
    promotions: 'https://promoapi.jpc.africa',
    environment: 'uat',
    bankingEnvironment: 'www.jackpotcity',
  },
  production: {
    kentico: 'https://cms1.jpc.africa',
    authApi: 'https://app.jpc.africa/auth',
    balance: 'https://app.jpc.africa/balance',
    player: 'https://app.jpc.africa/player',
    casinoBonus: 'https://casinobonusing.jpc.africa',
    config: 'https://config.jpc.africa',
    promotions: 'https://promoapi.jpc.africa',
    environment: 'production',
    bankingEnvironment: 'www.jackpotcity',
  },
};
