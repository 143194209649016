import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useAddMarketingSession } from '../useAddMarketingSession';
import type {
  IEditUserRequest,
  ILoginRequest,
  IRegisterRequest,
  ISubscriptionUpdateRequest,
} from '~/interfaces/dto/auth/request';
import type {
  IAddLimitRequest,
  IBankingAccountSettingsRequest,
} from '~/interfaces/dto/player/request';
import type {
  IAddLimitResponse,
  IGetLimitsResponse,
  IBankingAccountSettingsResponse,
} from '~/interfaces/dto/player/response';
import type {
  ILoginResponse,
  IRegisterResponse,
  IUserInfoExtendedResponse,
} from '~/interfaces/dto/auth/response';
import { useServices } from '../useServices';
import { brandIds } from '~/assets/data/config';

export const useAuthService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.authApi);
  const authStore = useAuthStore();
  const siteStore = useSiteStore();

  const retryAuthenticatedRequest = useRetryAuthenticatedRequest;

  const register = async (
    request: IRegisterRequest
  ): Promise<IRegisterResponse> => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/register` });
      const { username, countryCode, password } = request;
      return await $fetch<IRegisterResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          'Cache-Control': 'no-cache',
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        async onResponse({ response }) {
          if (!!response._data && response?._data?.isSuccessful) {
            await login({ username, countryCode, password }).then(() => {
              siteStore.deactivateModal();
            });
          } else {
            //Google Analytics for reg failing?
            console.error(response?._data?.error);
          }
          return response._data;
        },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const login = async (request: ILoginRequest): Promise<ILoginResponse> => {
    const res: globalThis.Ref<ILoginResponse> = ref();
    const { $walletService } = useServices();
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/authenticate` });
      await $fetch<ILoginResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        async onResponse({ response }) {
          if (!!response._data.data?.jwtToken) {
            authStore.setAccessToken(response._data.data.jwtToken);
            authStore.setRefreshToken(response._data.data.refreshToken);
            authStore.setUserId(response._data.data.id);
            authStore.setLoggedIn();
            await getUserExtended();
            // if (!!user.data) {
            //   await trackingMethods.identifyUser(user.data.id, user.data);
            // }
            await $walletService.fetchBalances();
            useAddMarketingSession();
            // await useCheckRestrictions();
            if (!authStore?.restrictionPermissions?.allowedWager) {
              if (authStore?.restrictionPermissions?.allowedWithdrawal) {
                siteStore.activateModal('accountRestricted');
              } else {
                siteStore.activateModal('accountLocked');
              }
            }
          }
          res.value = response._data;
          return response._data;
        },
      });

      return res.value;
    } catch (e) {
      console.error(e);
      return e;
    }
  };
  const handleLoginSession = async (queryStr: string) => {
    const refreshTokenStr: ILoginResponse['data']['refreshToken'] = JSON.parse(
      atob(queryStr)
    );
    authStore.setRefreshToken(refreshTokenStr);
    await refreshToken();
    await getUserExtended();
    authStore.setLoggedIn();
    const { $walletService } = useServices();
    await $walletService.fetchBalances();
  };

  const refreshToken = async () => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/refresh-token` });
      if (!authStore.currentRefreshToken) return;
      return await $fetch(requestUrl, {
        method: 'post',
        body: {},
        headers: {
          'Refresh-Token': `${authStore.currentRefreshToken}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        server: false,
        onResponse({ response }) {
          if (!!response._data.error) authStore.logout();
          const accessToken = response._data?.data?.jwtToken;
          const refreshToken = response._data?.data?.refreshToken;
          authStore.setAccessToken(accessToken);
          authStore.setRefreshToken(refreshToken);
        },
        onRequestError() {
          authStore.logout();
        },
        onResponseError() {
          authStore.logout();
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // user details
  const getUser = async () => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/user-info` });
      return await retryAuthenticatedRequest(requestUrl, {
        headers: {
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        onResponse({ response }) {
          authStore.setUser(response._data.data);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const getUserExtended = async () => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/user-info-extended`,
      });
      return await retryAuthenticatedRequest<IUserInfoExtendedResponse>(
        requestUrl,
        {
          onResponse({ response }) {
            const res = response?._data?.data;
            authStore.setUser(res);
            if (siteStore.getFeatureFlags.get('myaccount.enablecompliance')) {
              //set user compliant status for red dot alert on profile and doc-ver
              if (res?.compliance) {
                authStore.setDocumentVerificationStatus(
                  !(res?.compliance?.complianceStatus < 1)
                );
                authStore.setPartialPostReg(
                  res?.compliance?.complianceStatus < 1
                ); //If not Fica'd set partial post reg to true - this is to cover user refreshes during post reg
              }
              //Check if deceased or minor and log them out
              if (
                res?.compliance.hasOwnProperty('isValidId') &&
                !res?.compliance?.isValidId &&
                res?.compliance?.complianceStatus <
                  res?.compliance?.requiredComplianceStatus
              ) {
                authStore.logout();
              }
            }
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  };
  const editUser = async (request: IEditUserRequest) => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/edit-user` });
      return await $fetch(requestUrl, {
        method: 'put',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const addMobileNumber = async ({ accountId, countryCode, mobileNumber }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Contact/mobile-number/add`,
      });
      return await $fetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          mobileNumber,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const deleteMobileNumber = async ({
    accountId,
    countryCode,
    mobileNumber,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Contact/mobile-number/remove`,
      });
      return await $fetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          mobileNumber,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // password functions
  const passwordResetInit = async ({
    login,
    countryCode,
    contactPreferences,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change/initiate`,
      });
      return await $fetch(requestUrl, {
        method: 'post',
        body: {
          login,
          countryCode,
          contactPreferences,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const passwordResetConfirm = async ({
    password,
    login,
    countryCode,
    token,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change/confirm`,
      });
      return await $fetch(requestUrl, {
        method: 'post',
        body: {
          password,
          login,
          countryCode,
          token,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const passwordResetLoggedIn = async ({
    accountId,
    countryCode,
    password,
    confirmPassword,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change`,
      });
      return await $fetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          password,
          confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        async onResponseError({ response }) {
          //return error to throw general error on update password
          return response._data;
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // Account settings
  const fetchSubscriptions = async () => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.base,
        path: 'api/v1/Subscription/Notification',
      });
      return await $fetch<Record<string, unknown>>(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const updateSubscriptions = async (request: ISubscriptionUpdateRequest) => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.base,
        path: 'api/v1/Subscription/Notification',
      });
      return await $fetch(requestUrl, {
        method: 'put',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  //reversable withdrawal
  const getBankingAccountSettings = async () => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.player,
        path: '/v1/AccountSettings',
      });
      return await $fetch<IBankingAccountSettingsResponse>(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const upsertBankingAccountSettings = async (
    request: IBankingAccountSettingsRequest
  ) => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.player,
        path: '/v1/AccountSettings/upsert',
      });
      return await $fetch<IBankingAccountSettingsResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  //End account settings

  const getUserLimits = async () => {
    try {
      //TODO: FIX BASE
      const requestUrl = `https://uat-app.easterndawn.io/player/v1/Limits/user-limits`;
      //LazyFetch so it doesnt block navigation
      return await retryAuthenticatedRequest<IGetLimitsResponse>(requestUrl, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const addUserLimit = async (request: IAddLimitRequest) => {
    try {
      //TODO: FIX BASE
      const requestUrl = `https://uat-app.easterndawn.io/player/v1/Limits/add-limit`;
      return await $fetch<IAddLimitResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    register,
    login,
    handleLoginSession,
    getUser,
    getUserExtended,
    editUser,
    refreshToken,
    passwordResetLoggedIn,
    passwordResetInit,
    passwordResetConfirm,
    fetchSubscriptions,
    updateSubscriptions,
    getBankingAccountSettings,
    upsertBankingAccountSettings,
    addMobileNumber,
    deleteMobileNumber,
    getUserLimits,
    addUserLimit,
  };
};
