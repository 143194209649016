import { defineStore } from 'pinia';
import { useServices } from '~/composables/useServices';

class CaseInsensitiveMap<T, U> extends Map<T, U> {
  override set(key: T, value: U): this {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }
    return super.set(key, value);
  }

  override get(key: T): U | undefined {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }

    return super.get(key);
  }

  override has(key: T): boolean {
    if (typeof key === 'string') {
      key = key.toLowerCase() as any as T;
    }

    return super.has(key);
  }
}

interface ILocaleData {
  [key: string]: string;
}

interface ILocaleStore {
  debug: boolean;
  messages: ILocaleData; // Replace 'ILocaleData' with the actual interface for your locale data
  messagesMap: CaseInsensitiveMap<string, string>;
  locale: string;
  localeOptions: Record<string, string>[];
}

export const useLocaleStore = defineStore('locale', {
  state: (): ILocaleStore => {
    return {
      debug: false,
      messages: {},
      messagesMap: new CaseInsensitiveMap(),
      locale: '',
      localeOptions: [{}],
    };
  },
  actions: {
    toggleLocaleDebug(): void {
      this.debug = !this.debug;
    },
    async changeLocale(locale: string) {
      this.setLocaleCode(locale);
      await this.fetchLocale(locale);
    },
    async fetchLocale(locale: string) {
      this.setLocaleCode(locale);
      const { $localeService } = useServices();
      const data = await $localeService.fetchLocale(locale);
      if (!!data) {
        this.setLocale(data);
        return data;
      }
    },
    setLocaleCode(locale: string) {
      const { $storageService } = useServices();
      $storageService.setCookie({ key: 'locale', data: locale, expiry: 1 });
      this.locale = locale;
    },
    setLocale(data: Record<string, string>) {
      this.messagesMap = new CaseInsensitiveMap(Object.entries(data));
    },
    async setLocaleOptions(data) {
      const options = [];
      data.forEach((el) => {
        options.push({
          cultureCode: el.cultureCode,
          translation: el.translation,
        });
      });
      this.localeOptions = options;
    },
  },
  getters: {
    getLocale(state) {
      return state.locale || 'en-US';
    },
    debugEnabled(state) {
      return state.debug;
    },
    localeKeyInsensitive(state) {
      return (key: string) => {
        return state?.messages[
          Object.keys(state.messages).find(
            (k) => k?.toLowerCase() === key?.toLowerCase()
          )
        ];
      };
    },
    localeKey(state) {
      return (key: string, alternativeKey?: string) => {
        try {
          if (state.debug) {
            console.info({ key, value: state.messages[key] || null });
            return `${key} | ${!state.messages[key] ? 0 : 1}`;
          }
          if (state.messagesMap.size === 0) {
            const splitKey = key.split('.');
            return splitKey[splitKey.length - 1];
          }
          if (state.messagesMap.size > 0)
            return (
              state.messagesMap.get(key) ||
              state.messagesMap.get(alternativeKey) ||
              key
            );
        } catch (e) {
          console.error({ e });
        }
      };
    },
    getLocaleOptions(state) {
      return state.localeOptions;
    },
    localHydrated(state) {
      return Object.keys(state?.messages)?.length;
    },
  },
});
